<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-14 18:11:47
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 10:22:32
 * @Description: 查询快件信息
 * @FilePath: \src\views\StockManage\QueryExpressInfo.vue
-->

<template>
  <div class="kc-app">
    <van-nav-bar
      title="快件信息查询"
      left-arrow
      :fixed="true"
      :placeholder="true"
      :z-index="500"
      :safe-area-inset-top="true"
      @click-left="$router.go(-1)"
    />

    <van-field
      v-model="express_id"
      label="快递单号"
      ref="express_input_dom"
      name="express_input_dom"
      autocomplete="off"
      placeholder="请输入快递单号 不少于6位"
    />

    <div class="resule-box" v-if="express_info.kec_id !== undefined">
      <van-cell-group inset>
        <van-cell title="客户昵称" :value="express_info.kcu_name" />
        <van-cell title="仓库名称" :value="express_info.kw_name" />
        <van-cell title="快件状态">
          <template #value>
            <van-tag v-if="express_info.status == 150010" type="primary">
              已入库
            </van-tag>
            <van-tag v-else-if="express_info.status == 150012" type="success">
              在库
            </van-tag>
            <van-tag v-else-if="express_info.status == 150013" type="warning">
              已拣出
            </van-tag>
            <van-tag v-else type="danger"> 未知 </van-tag>
          </template>
        </van-cell>
        <van-cell title="库位导航" :value="express_info.role" />
        <van-cell title="入库时间" :value="express_info.add_wh_time" />
        <van-cell
          title="上架时间"
          v-if="express_info.status == 150012"
          :value="express_info.to_shelves_time"
        />
        <van-cell
          title="拣出时间"
          v-if="express_info.status == 150013"
          :value="express_info.picking_time"
        />
      </van-cell-group>
    </div>

    <van-divider></van-divider>

    <div class="btn-box">
      <van-button
        style="width: 30%; margin-right: 15px"
        round
        size="small"
        icon="exchange"
        type="success"
        @click="queryExpressInfo"
      >
        查询
      </van-button>
      <van-button
        style="width: 30%"
        round
        size="small"
        icon="replay"
        type="default"
        @click="init"
      >
        重置
      </van-button>
    </div>
  </div>
</template>
<script>
import { getCurrentInstance, ref } from "vue";
import { a_queryExpressInfo } from "./api";
export default {
  setup() {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;
    const express_id = ref(""); //快递单号
    const express_info = ref({}); //快递信息

    //查询快件信息
    const queryExpressInfo = () => {
      if (express_id.value !== "") {
        if (express_id.value.length >= 6 && express_id.value.length <= 20) {
          let jq = express_id.value.substr(0, 4);
          if (jq !== "CNFY") {
            query(); //提交查询
          } else {
            utils.fail("编码不受支持！", 800);
            init(); //初始化
          }
        } else {
          utils.fail("操作失败！", 800);
          init(); //初始化
        }
      } else {
        utils.fail("请输入快递单号！", 800);
        init(); //初始化
      }
    };

    //提交查询
    const query = () => {
      a_queryExpressInfo({
        express_id: express_id.value,
      })
        .then((res) => {
          console.log(res.data);
          express_info.value = res.data;
          utils.success(res.err_msg);
        })
        .catch((err) => {
          utils.fail(err.err_msg);
        });
    };

    //初始化参数
    const init = () => {
      express_id.value = "";
      express_info.value = {};
    };

    return {
      express_id,
      express_info,
      init,
      queryExpressInfo,
    };
  },
};
</script>
<style>
.resule-box {
  margin-top: 25px;
}
</style>
